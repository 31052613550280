<template>
  <div class="flex flex-col text-left">
    <div class="flex flex-col">
      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Ziel</label>
          <input
            type="text"
            v-model="$v.goal.name.$model"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            :class="{ 'border-red-500': $v.goal.name.$error }"
          />
        </div>
      </div>

      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Beschreibung</label>
          <textarea
            v-model="goal.description"
            rows="7"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { xhr } from '@/utils/axios'
import { required } from 'vuelidate/lib/validators'

const tempGoal = {
  name: '',
  description: '',
}

export default {
  name: 'new-goal',
  props: ['payload', 'parent_id'],
  components: {},
  data() {
    return {
      goal: { ...tempGoal },
    }
  },
  validations() {
    return {
      goal: {
        name: {
          required,
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.goal = { ...this.payload }
    },
    save() {
      this.$v.goal.$touch()

      if (this.$v.$invalid) {
        this.$toast.warning('Bitte füllen Sie die erforderlichen Felder aus')
      } else {
        this.goal['roleprofile'] = this.parent_id
        this.goal['personal'] = this.parent_id

        if (this.goal.id) {
          xhr
            .put(`/personal/goal/${this.goal.id}/`, this.goal, {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(() => {
              this.$emit('close-modal', { show: false })
              // this.$toast.success(response.data.message)
            })
        } else {
          xhr
            .post(`/personal/goal/`, this.goal, {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(() => {
              this.$emit('close-modal', { show: false })
              // this.$toast.success(response.data.message)
            })
        }
      }
    },
  },
  watch: {},
}
</script>
